import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import * as jwt_decode from "jwt-decode";
import { AlertComponent, AlertConfig } from "ngx-bootstrap/alert";
import { UserService } from "../data/user/user.service";

declare var $: any;

export function getAlertConfig(): AlertConfig {
  return Object.assign(new AlertConfig(), { type: "success" });
}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [{ provide: AlertConfig, useFactory: getAlertConfig }]
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  step: number;
  name: string;
  errorMessage: any;
  alerts: any[] = [];
  invalidCredentials = false;
  timeoutRef = null;

  constructor(
    private userService: UserService,
    private router: Router,
    private fb: FormBuilder) { }

  ngOnInit() {
    if (localStorage.getItem("name")) {
      this.router.navigate(["dynamic"]);
      return;
    }
    this.step = 1;
    this.createForm();
  }
  // add(msg, type): void {
  //   this.alerts.push({
  //     type,
  //     msg,
  //     timeout: 4000
  //   });
  // }
  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }
  submit() {
    if (this.loginForm.valid) {
      this.userService.loginUser(this.loginForm.value).subscribe(
        res => {
          if (res.code === 403) {
            this.step = 2;
            this.loginForm.controls.name.setValidators(Validators.required);
            this.loginForm.controls.newPassword.setValidators([Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[^ \t]{8,}$/)]);
          } else {
            const data = jwt_decode(res.idToken);
            localStorage.setItem("refreshToken", res.refreshToken);
            localStorage.setItem("accessToken", res.accessToken);
            localStorage.setItem("name", data["name"]);
            localStorage.setItem("role", data["cognito:groups"]);
            localStorage.setItem("cityId", data["custom:cityId"]);
            // Use this if you want to modify the initial page for different admin roles
            // const navigateTo = (data["cognito:groups"][0] === "marketingAdmin") ? "/passengers" : "/passengers";
            const navigateTo = "/passengers";
            this.router.navigate([navigateTo]);
          }
        },
        error => {
          if (error.code === 406) {
            console.log("Login -> Error: ", error);
          }
          // this.add(error.message, "danger");
          this.displayErrorMessage(error.message);
        }
      );
    }
  }

  forgotPassword(email: string) {
    this.userService.forgotPassword(email).subscribe(res => {
      alert(res);
    });
  }

  createForm() {
    this.loginForm = this.fb.group({
      username: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[^ \t]{8,}$/)]],
      name: [null],
      newPassword: [null]
    });
  }

  displayErrorMessage(text: string) {
    clearTimeout(this.timeoutRef);
    $("#login-error-div").show();
    $("#login-error-div span").text(text);
    this.timeoutRef = setTimeout(() => {
      $("#login-error-div").hide();
    }, 4000);
  }
}
