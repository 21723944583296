import { Component, OnInit } from "@angular/core";
import { Http, ResponseContentType } from "@angular/http";
import * as fileSaver from "file-saver";
import { PayoutStatus } from "../data/payouts/payout";
import { PayoutClientType, PayoutService } from "../data/payouts/payout.service";
import { displayErrorMessage } from "app/shared/error-div";

declare var $: any;

@Component({
  selector: "app-company-payouts",
  templateUrl: "./company-payouts.component.html",
  styleUrls: ["./company-payouts.component.scss"]
})
export class CompanyPayoutsComponent implements OnInit {
  selectedPayoutPeriod: any;
  payoutPeriods = [];
  payouts = [];
  spinner = true;
  PayoutStatus = PayoutStatus;
  isSetPayoutFailedModalOpened = false;
  messageText = "";
  selectedPayoutId: string;
  selectedPayout;
  isViewDetailsModalOpened = false;
  isFailureReasonModalOpened = false;
  actionsDivDisplay = false;
  isXmlExecutionDateModalOpened = false;
  xmlExecutionDate = "";
  currency;
  clientType = PayoutClientType.company;
  generateXmlClicked: boolean = false;

  constructor(private payoutService: PayoutService, private http: Http) { }

  async ngOnInit() {
    await this.getPayoutPeriods();
    this.selectedPayoutPeriod = this.payoutPeriods[0];
    this.getPayoutsForPeriod(this.selectedPayoutPeriod);
  }

  async getPayoutPeriods() {
    const periods = await this.payoutService.getPayoutPeriods(this.clientType).toPromise();
    this.payoutPeriods = periods.sort((a, b) => b.year - a.year);
  }

  async getPayoutsForPeriod(period) {
    const query = `year=${period.year}&weekOfYear=${period.weekOfYear}`;
    const payoutsResponse = await this.payoutService.getPayoutsForPeriod(this.clientType, query).toPromise();
    this.payouts = payoutsResponse.payouts;
    this.currency = payoutsResponse.currency;
    this.actionsDivDisplay = this.payouts.some(item => item.payoutStatus === PayoutStatus.DONE);
    this.spinner = false;
  }

  changePeriod() {
    return this.getPayoutsForPeriod(this.selectedPayoutPeriod);
  }

  async calculatePayouts() {
    await this.payoutService.calculatePayouts(this.clientType).toPromise();
    this.getPayoutsForPeriod(this.selectedPayoutPeriod);
  }

  async generateXml() {
    this.generateXmlClicked = true;
    const body = {
      ...this.selectedPayoutPeriod,
      executionDate: this.xmlExecutionDate
    };
    try {
      const response = await this.payoutService.generateXml(this.clientType, body).toPromise();
      await this.downloadFile(response.url);
      this.getPayoutsForPeriod(this.selectedPayoutPeriod);
    } catch (err) {
      displayErrorMessage(err.message ? err.message : "Unexpected error occured");
    }
    $("#xmlExecutionDateModal").modal("hide");
    this.generateXmlClicked = false;
  }

  async downloadFile(url) {
    const response = await this.http.get(url, { responseType: ResponseContentType.Blob }).toPromise();
    const blob: any = new Blob([response.blob()]);
    fileSaver.saveAs(blob, `${this.clientType}_payout_${this.selectedPayoutPeriod.year}_${this.selectedPayoutPeriod.weekOfYear}.xml`);
  }

  async confirmPayouts() {
    await this.payoutService.confirmPayouts(this.clientType, this.selectedPayoutPeriod).toPromise();
    this.getPayoutsForPeriod(this.selectedPayoutPeriod);
  }

  openModal(payoutId: string) {
    $("#setPayoutFailedForm")[0].reset();
    this.selectedPayoutId = payoutId;
    this.messageText = "";
    this.isSetPayoutFailedModalOpened = !this.isSetPayoutFailedModalOpened;
  }

  setFailed() {
    this.payoutService.setFailed(this.clientType, this.selectedPayoutId, this.messageText).toPromise();
    $("#setPayoutFailedModal").modal("hide");
    this.getPayoutsForPeriod(this.selectedPayoutPeriod);
  }

  openDetailsModal(payout) {
    this.selectedPayout = payout;
    this.isViewDetailsModalOpened = !this.isViewDetailsModalOpened;
  }

  openFailureReasonModal(payout) {
    this.selectedPayout = payout;
    this.isFailureReasonModalOpened = !this.isFailureReasonModalOpened;
  }

  openXmlExecutionDateModal() {
    this.xmlExecutionDate = "";
    $("#addXmlExecutionDateForm")[0].reset();
    this.isXmlExecutionDateModalOpened = !this.isXmlExecutionDateModalOpened;
  }

  validateExecutionDate(event: any) {
    const executionDate = event.target.value.replace(/[^0-9\.]/g, "");
    return executionDate;
  }

  ngOnDestroy() {
    $(".modal .close").click();
  }

}
