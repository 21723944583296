
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as HttpCodes from "http-status-codes";
import { throwError as observableThrowError } from "rxjs";
import "rxjs/Rx";
import { Observable } from "rxjs/Rx";
import { EnvService } from "../../env.service";
import { displayErrorMessage } from "app/shared/error-div";

let apiUrl;
declare var $: any;

@Injectable()
export class BackendService {

  constructor(public http: HttpClient, public env: EnvService) {
    apiUrl = this.env.apiUrl;
  }

  getBackend(query: string, header?: any): Observable<any> {
    let headers = new HttpHeaders({});

    if (header) {
      headers = headers.append("refreshToken", header);
    }
    return this.http.get(apiUrl + query, { headers })
      .map(this.extractJson)
      .catch(this.handleError);
  }

  postBackend(query: string, data: any): Observable<any> {
    const body = JSON.stringify(data);
    const headers = new HttpHeaders(
      {
        "Content-Type": "application/json",
      });

    return this.http.post(apiUrl + query, body, { headers })
      .map(this.extractJson)
      .catch(this.handleError);
  }

  postBackendFormData(query: string, data: any): Observable<any> {
    const headers = new HttpHeaders({});
    return this.http.post(apiUrl + query, data, { headers })
      .map(this.extractJson)
      .catch(this.handleError);
  }

  putBackend(query: string, data: any): Observable<any> {
    const body = JSON.stringify(data);
    const headers = new HttpHeaders(
      {
        "Content-Type": "application/json"
      });
    return this.http.put(apiUrl + query, body, { headers })
      .map(this.extractJson)
      .catch(this.handleError);
  }

  deleteBackend(query: string): Observable<any> {
    const headers = new HttpHeaders({ });
    return this.http.delete(apiUrl + query, { withCredentials: false, headers })
      .map(this.extractJson)
      .catch(this.handleError);
  }

  getImage(url: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.withCredentials = false;
      xhr.responseType = "blob";
      xhr.onload = (e) => {
        resolve(xhr.response);
      };
      xhr.send();
    });
  }

  private handleError(errorData: any) {
    console.log("handleError ->", errorData);
    if (errorData.status !== HttpCodes.NOT_FOUND && errorData.status !== 0) {
      const text = (errorData.status === HttpCodes.UNAUTHORIZED) ? "Limited or restricted access" : errorData.error.message;
      displayErrorMessage(text);
    }

    return observableThrowError(errorData.error);
  }

  private extractJson(value: HttpResponse<any>) {
    const json = value;
    if (json) {
      return json;
    } else {
      throw new Error("No response");
    }
  }
}
