import { Injectable } from "@angular/core";
import { BackendService } from "../backend";

@Injectable()
export class PassengerService {
    constructor(private backendService: BackendService) { }

    getPassengers(query: string) {
        return this.backendService.getBackend(`/passenger?${query}`)
            .map(response => response);
    }

    deletePassenger(id: string) {
        return this.backendService.deleteBackend(`/user/passenger/${id}`)
            .map(response => response);
    }

    blockPassenger({ phoneNumber, note }: { phoneNumber: string, note: string }) {
        return this.backendService.postBackend("/passenger/phone-blacklist", {
            phoneNumber,
            note
        }).map(response => response);
    }

    unblockPassenger(phoneNumber: string) {
        return this.backendService.deleteBackend(`/passenger/phone-blacklist/${phoneNumber}`)
            .map(response => response);
    }
}
