import { Pipe, PipeTransform } from "@angular/core";
import POSTAL_CODES from "../../data/ride/postal-codes.json";

@Pipe({
    name: "formatPostalCode"
})
export class FormatPostalCodePipe implements PipeTransform {

    transform(postalCode: string, countryName: string): any {
        if (postalCode) {
            return postalCode !== "N/A" ?
                `${POSTAL_CODES[countryName][postalCode].groupName} | ${postalCode}` :
                postalCode;
        }
    }

}
