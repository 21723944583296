import { Component } from "@angular/core";
import { Event, NavigationEnd, NavigationStart, Router } from "@angular/router";

declare var $: any;

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: "<router-outlet></router-outlet>"
})
export class AppComponent {
  constructor(private router: Router) {

    router.events.subscribe((event: Event) => {

      if (event instanceof NavigationStart) {
        // Show loading indicator
        $("#error-div").hide();
        $("#success-div").hide();
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
      }

    });

  }
}
