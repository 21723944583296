import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DriversFilterPipe } from "./drivers-filter.pipe";
import { FilterOnlineClients } from "./filterOnlineClients.pipe";
import { FormatCamelCasePipe } from "./format-camel-case.pipe";
import { FormatCurrencyPipe } from "./format-currency.pipe";
import { FormatDistancePipe } from "./format-distance.pipe";
import { FormatPacePipe } from "./format-pace.pipe";
import { FormatTimeRangePipe } from "./format-time-range.pipe";
import { FormatTimePipe } from "./format-time.pipe";
import { FormatWeekPeriodPipe } from "./format-week-period.pipe";
import { OrdinalNumberPipe } from "./ordinal-number.pipe";
import { SimpleTextFilterPipe } from "./simple-text-filter.pipe";
import { FormatPostalCodePipe } from "./format-postal-code.pipe";
import { SortByPipe } from "./sort-by.pipe";

@NgModule({
    declarations: [FilterOnlineClients, FormatCamelCasePipe, FormatTimePipe, SortByPipe, FormatDistancePipe, DriversFilterPipe, SimpleTextFilterPipe, FormatWeekPeriodPipe, FormatTimeRangePipe, OrdinalNumberPipe, FormatCurrencyPipe, FormatPacePipe, FormatPostalCodePipe],
    imports: [CommonModule],
    exports: [FilterOnlineClients, FormatCamelCasePipe, FormatTimePipe, SortByPipe, FormatDistancePipe, DriversFilterPipe, SimpleTextFilterPipe, FormatWeekPeriodPipe, FormatTimeRangePipe, OrdinalNumberPipe, FormatCurrencyPipe, FormatPacePipe, FormatPostalCodePipe]
})
export class PipesModule {
    static forRoot() {
        return {
            ngModule: PipesModule,
            providers: [],
        };
    }
}
